import React from "react"
import { useSpring, animated, config } from "react-spring"
import { Container, Layout, Section } from "../components"
import SEO from "../components/seo"

const TypographyPage = props => {
  const fadeUp = useSpring({
    transform: "translateY(0)",
    opacity: 1,
    from: { transform: "translateY(30px)", opacity: 0 },
    config: config.molasses,
  })
  return (
    <Layout>
      <SEO title="Typography" />
      <animated.div style={fadeUp}>
        <Section>
          <Container>
            <h1>
              Heading 1 - Maybe there's a little something happening right here
            </h1>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <h2>
              Heading 2 - Maybe there's a little something happening right here
            </h2>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <h3>
              Heading 3 - Maybe there's a little something happening right here
            </h3>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <h4>
              Heading 4 - Maybe there's a little something happening right here
            </h4>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <h5>
              Heading 5 - Maybe there's a little something happening right here
            </h5>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <h6>
              Heading 6 - Maybe there's a little something happening right here
            </h6>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <p>
              Maybe there's a little something happening right here. When you do
              it your way you can go anywhere you choose. We'll throw some old
              gray clouds in here just sneaking around and having fun. Go out on
              a limb - that's where the fruit is. Only eight colors that you
              need. If you don't like it - change it. It's your world.
            </p>
            <ul className="custom checkmark">
              <li>Item 1</li>
              <li>Item 2</li>
              <li>Item 3</li>
              <li>Item 4</li>
            </ul>
            <ul>
              <li>Item 1</li>
              <li>Item 2</li>
              <li>Item 3</li>
              <li>Item 4</li>
            </ul>
            <ol>
              <li>Item 1</li>
              <li>Item 2</li>
              <li>Item 3</li>
              <li>Item 4</li>
            </ol>
          </Container>
        </Section>
      </animated.div>
    </Layout>
  )
}

export default TypographyPage
